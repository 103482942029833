<template>
	<div class="row justify-content-center px-3 certificates-message" style="margin-top:10px">
	  <div class="col-12 text-center py-10 " v-if="certificates.length == 0">
		<strong>Usted aún no tiene certificados</strong>
	  </div>
	  <template v-else>
		<div class="col-12 col-md-4 order-2 order-md-1">
		  <div class=" py-1">
			<div class="">
			  <p class="card-title">
				<strong>
				  <h2 style="font-family: 'Segoe UI';color: #2D383C;">Mis certificados</h2>
				</strong>
				En total lograste {{ totalDiplomas }} diplomas
			  </p>
			  <div class="d-flex align-items-center">
				<label for="filter" class="mr-2" style="color: #A9A9A9;">Filtrar por: </label>
				<select class="form-control" id="filter" v-model="selectedFilter" style="max-width: 150px; border-radius: 10px">
				  <option value="todos">Todos</option>
				  <option value="curso">Cursos</option>
				  <option value="diplomado">Diplomados</option>
				</select>
			  </div>
			</div>
		  </div>
  
		  <div class="overflow-auto" style="height: calc(100vh - 190px);">
			<div v-for="certificate in certificates" :key="certificate.id">
			  <div class="card py-1 pointer" @click="showCertificate(certificate)">
				<div class="col-md-12 row">
				  <div class="col-4 mx-auto text-center d-flex align-items-center">
					<img class="img-thumbnail" :src="certificate.path" v-loading-spinner>
				  </div>
				  <div class="col-8 px-1">
					<div class="card-body">
					  <p class="card-title text-semibold" style="font-family: 'Segoe UI'; color: #2D383C;">{{ certificate.name_certificate }}</p>
					</div>
  
					<!--BOTONES DE DESCARGA DE CERTIFICADOS-->
					<div class="text-center">
					  <button style="border-radius: 10px;" type="button" class="btn btn-warning" id="button" @click="download('pdf')">
						<i></i>
						Descargar Pdf
					  </button>
					  <button type="button" style="border-radius: 10px;" class="btn btn-warning" id="button" @click="download('png')">
						<i></i>
						Descargar Png
					  </button>
					</div>
				  </div>
				</div>
			  </div>
			</div>
		  </div>
		</div>
		<div class="col-12 col-md-8 order-1 order-md-2 grid">
		  <div v-if="waitSelection">
			<div class="text-center py-10">
			  [ Seleccione un certificado ]
			</div>
		  </div>
		  <div v-else class="img__certificado">
			<v-overlay :absolute="true" :value="overlay"></v-overlay>
			<div class="certificate-container">
			  <img :src="certificateSelected.path" class="certificate-image img-thumbnail" v-loading-spinner>
			</div>
		  </div>
		</div>
	  </template>
	  <v-snackbar v-model="snackbar" v-if="snackbar!=true">
		<p class="text m-0 p-0" style="color: aliceblue">A ocurrido un error al descargar el archivo</p>
		<template v-slot:action="{ attrs }">
		  <v-btn color="red" v-bind="attrs" @click="snackbar = false">
			x
		  </v-btn>
		</template>
	  </v-snackbar>
	</div>
  </template>
  
<script>
export default {
  data() {
    return {
      certificates: [],
      waitSelection: true,
      certificateSelected: [],
      snackbar: false,
      overlay: false,
	  selectedFilter: 'todos'
    };
  },
  methods: {
    async getCertificates() {
      await this.$axios.post(`/sst-clients/user/get-certificates`).then((response) => {
        this.certificates = response.data;
      });
    },
    showCertificate(certificate) {
      this.certificateSelected = certificate;
      this.waitSelection = false;
    },
    async download(type) {
      try {
        this.overlay = true;
        const response = await this.$axios({
          url: `/public/sstclient/download-certificate?id=${this.certificateSelected.id}&type=${type}`,
          method: 'GET',
          responseType: 'blob'
        });

        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        if (type === 'png') {
          link.setAttribute('download', 'Certificado.png');
        } else {
          link.setAttribute('download', 'Certificado.pdf');
        }

        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);

        this.snackbar = true;
      } catch (error) {
        console.log({ error });
        this.snackbar = true;
      } finally {
        this.overlay = false;
      }
    }
  },
  computed: {
    totalDiplomas() {
      return this.certificates.length;
    },
	filteredCertificates() {
      if (this.selectedFilter === 'todos') {
        return this.certificates;
      } else {
        return this.certificates.filter(certificate => certificate.type === this.selectedFilter);
      }
    }
  },
  mounted() {
    this.getCertificates();
  }
};
</script>

<!-- 
<script>
export default {
	data() {
		return {
			certificates: [],
			waitSelection: true,
			certificateSelected: [],
			snackbar: false,
			overlay: false
		}
	},
	methods: {
		async getCertificates() {
			await this.$axios.post(`/sst-clients/user/get-certificates`).then((response) => {
				this.certificates = response.data;
			})
		},
		showCertificate(certificate) {
			this.certificateSelected = certificate;
			this.waitSelection = false;
		},
		async download(type) {
			try {
				this.overlay = true;
				const response = await this.$axios({
					url: `/public/sstclient/download-certificate?id=${this.certificateSelected.id}&type=${type}`,
					method: 'GET',
					responseType: 'blob'
				});

				const url = window.URL.createObjectURL(new Blob([response.data]));
				const link = document.createElement('a');
				link.href = url;
				if (type === 'png') {
					link.setAttribute('download', 'Certificado.png');
				}else{
					link.setAttribute('download', 'Certificado.pdf');
				}
				
				document.body.appendChild(link);
				link.click();
				document.body.removeChild(link);

				this.snackbar = true;
			} catch (error) {
				console.log({ error })
				this.snackbar = true;
			} finally {
				this.overlay = false;
			}

		}
	},
	mounted() {
		this.getCertificates();
	}
}
</script> -->

<style>
.img__certificado{
	margin-top: 70px;
	margin-left: 40px;
}
.certificates-message {
  margin-left: 60px; /* Ajusta el valor del margen según tus necesidades */
}
.pointer {
	cursor: pointer
}

.d-grid {
	display: grid;
}

.place-center {
	place-items: center;
}
#button{
	margin-left: 10px;
}
</style>
<style>
.certificate-container {
    width: 691px; /* Dimensión de ancho deseada */
    height: 487px; /* Dimensión de alto deseada */
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden; /* Evita que las imágenes sobrepasen el contenedor */
}

.certificate-image {
    width: 100%;
    height: auto;
    object-fit: contain;
}
</style>