<template>
    <Certificates />
</template>
<script>
import Certificates from "@/components/auth/certificates/Certificates.vue";
export default {
    components: {
        Certificates,
    }
}
</script>